// // This file is automatically compiled by Webpack, along with any other files
// // present in this directory. You're encouraged to place your actual application logic in
// // a relevant structure within app/javascript and only use these pack files to reference
// // that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
// import jquery from 'jquery'
// require("jquery")
// import initVueApp from "@/main.ts"

// initVueApp()

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// import 'normalize-css/normalize.css'
//import 'element-plus/lib/theme-chalk/index.css'
// import 'bootstrap/scss/bootstrap.scss'
// require('bootstrap')
import 'bootstrap'
// require('bootstrap')
// import './bootstrap.min.js'
import "./stylesheets/application.scss"
// const importAllCSS = (r) => r.keys().forEach(r)
// importAllCSS(require.context('../dest/orders/css', true, /\.css$/))


